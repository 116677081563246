<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b">
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>COA</b> yang ada di Klinik Hayandra
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/coas/add')"
        v-if="btn">Tambah</b-button>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <div class="row justify-content-between">
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama COA"
                    @keyup="filterByName"></b-form-input>
                </b-input-group>
              </div>
              <div class="col-md-3">
                <b-input-group>
                  <treeselect
                    v-model="filter.coa_category_id"
                    :multiple="false"
                    :options="coa_categories"
                    @input="filterByCOACategory" />
                </b-input-group>
              </div>
              <div class="col-md-3">
                <b-input-group>
                  <treeselect
                    v-model="filter.cash_flow_id"
                    :multiple="false"
                    :options="cash_flows"
                    @input="filterByCashFlow" />
                </b-input-group>
              </div>
              <div class="col-md-3">
                <b-input-group>
                  <treeselect
                    v-model="filter.sub_ledger_id"
                    :multiple="false"
                    :options="sub_ledgers"
                    @input="filterBySubLedger" />
                </b-input-group>
              </div>
            </div>

            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive>
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }" />
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="
                    $router.push({ path: '/coas/detail/' + data.item.id })
                  "><i class="fas fa-eye px-0"></i></b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="$router.push({ path: '/coas/edit/' + data.item.id })"
                  v-if="btn"><i class="fas fa-edit px-0"></i></b-button>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="deleteData(data.item.id)"
                  v-if="btn"><i class="fas fa-trash px-0"></i></b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"></b-pagination>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue";
import { debounce } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
        coa_category_id: "",
        sub_ledger_id: "",
        cash_flow_id: "",
      },
      coa_categories: [],
      sub_ledgers: [],
      cash_flows: [],
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "list_number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "number",
          label: "Nomor",
          sortable: true,
        },
        {
          key: "coa_category_name",
          label: "Kategori COA",
          sortable: true,
        },
        {
          key: "sub_ledger_name",
          label: "SubLedger",
          sortable: true,
        },
        {
          key: "cash_flow_name",
          label: "Cash Flow",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // User Access
      btn: false,
    };
  },

  methods: {
    async getCoaCategoryOption() {
      let response = await module.setTreeSelect("coa-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoad = true;
        this.coa_categories = response.data;
        this.coa_categories.unshift({
          label: "Pilih Kategori COA",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getSubLedgerOption() {
      let response = await module.setTreeSelect("sub-ledgers");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoad = true;
        this.sub_ledgers = response.data;
        this.sub_ledgers.unshift({
          label: "Pilih Kategori Sub Ledger",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getCashFlowOption() {
      let response = await module.setTreeSelect("cash-flows");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoad = true;
        this.cash_flows = response.data;
        this.cash_flows.unshift({
          label: "Pilih Kategori Cash Flow",
          id: "",
          isDisabled: true,
        });
      }
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&coa_category_id=${this.filter.coa_category_id}&sub_ledger_id=${this.filter.sub_ledger_id}&cash_flow_id=${this.filter.cash_flow_id}`;
      let response = await module.paginateCoa(
        `coas`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    filterByName() {
      debounce(() => { this.pagination() }, 500)
    },

    async filterByCOACategory(evt) {
      if (!evt) {
        this.filter.coa_category_id = await "";
        this.pagination();
      } else {
        this.filter.coa_category_id = await evt;
        this.pagination();
        console.log("ok");
      }
    },
    async filterByCashFlow(evt) {
      if (!evt) {
        this.filter.cash_flow_id = await "";
        this.pagination();
      } else {
        this.filter.cash_flow_id = await evt;
        this.pagination();
        console.log("ok");
      }
    },
    async filterBySubLedger(evt) {
      if (!evt) {
        this.filter.sub_ledger_id = await "";
        this.pagination();
      } else {
        this.filter.sub_ledger_id = await evt;
        this.pagination();
        console.log("ok");
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("coas/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6010") {
          this.btn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "COA" }]);
    // Get Data
    this.pagination();
    this.getCoaCategoryOption();
    this.getSubLedgerOption();
    this.getCashFlowOption();
    this.setActiveButton();
  },

  // watch: {
  //   'filter.coa_category_id': function (newVal, oldVal) {
  //     if (typeof newVal === 'undefined') {
  //       this.filter.coa_category_id = ''
  //       this.pagination()
  //     }
  //   },
  //   'filter.cash_flow_id': function (newVal, oldVal) {
  //     if (typeof newVal === 'undefined') {
  //       this.filter.cash_flow_id = ''
  //       this.pagination()
  //     }
  //   },
  //   'filter.sub_ledger_id': function (newVal, oldVal) {
  //     if (typeof newVal === 'undefined') {
  //       this.filter.sub_ledger_id = ''
  //       this.pagination()
  //     }
  //   }
  // },
};
</script>
